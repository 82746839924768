@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --gutter: 16px;
  --container-width: 1208px;
  --container-wide-width: 1440px;

  @screen md {
    --gutter: 32px;
  }
}


html {
  scroll-padding-top: 140px;
}

pre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

h1, h2, h3, h4, h5, h6, div, p, span, a, button, img, section {
  transition: color 1.5s var(--bg-effect-transition-timing-function);
  transition-property: color, background, background-color, filter;
}


@layer utilities {
  .scrollbar-0 {
    scrollbar-width: none;
  }

  .container {
    width: 100%;
    max-width: calc(var(--container-width) + (var(--gutter) * 2));
    padding: 0 var(--gutter);
    margin: 0 auto;
  }
  
  .wide-container {
    max-width: calc(var(--container-wide-width) + (var(--gutter) * 15));
    padding: 0 calc(var(--gutter) * 7.5);
    margin: 0 auto;
  
    @media (max-width: 1920px) {
      --max-container-wide-width: calc(var(--container-wide-width) + (var(--gutter) * 15));
      --adjust-padding: calc(var(--gutter) * 7.5 - (var(--max-container-wide-width) - 100vw) * 0.5);
      padding: 0 var(--adjust-padding);
    }
  }

  .ol-custom ol {
    @apply list-none p-0 mb-8; /* Remove default markers and padding */
    counter-reset: list-counter; /* Initialize the counter */
  }
  
  .ol-custom ol li {
    @apply relative my-4 pl-10; /* Position relative, spacing between items, and padding-left for marker space */
  }
  
  .ol-custom ol li:before {
    @apply absolute rounded-full w-8 h-8 flex items-center justify-center font-bold; /* Styled marker */
    content: counter(list-counter) !important; /* Display the counter value */
    counter-increment: list-counter; /* Increment the counter */
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}


/*
Defaults
*/

#nprogress .bar {
  z-index: 999999 !important;
}

.osano-cm-widget {
  display: none;
}

.osano-cm-dialog {
  transition-delay: 0ms,0ms !important;
  transition-duration: 0s,0ms !important;
}


/*
Prismic Rich Text
*/

.richtext {
  @apply mb-5;

  h2,
  h3,
  h4,
  p,
  ol,
  ul,
  li {
    &:last-child {
      @apply mb-0;
    }
  }

  h2,
  h3,
  h4,
  h5 {
    @apply mt-8 mb-4 lg:mt-16 lg:mb-8;
  }

  h1 {
    @apply text-46;
  }
  h2 {
    @apply text-24 lg:text-34;
  }

  h3 {
    @apply text-20 lg:text-28;
  }

  h4,
  h5 {
    @apply text-16 font-medium lg:text-20;
  }

  p {
    @apply mb-3 break-words lg:mb-5;

    & img {
      @apply mb-0;
    }
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  ul,
  ol {
    @apply mb-4 ml-5 lg:mb-6;

    li {
      @apply pt-2 font-normal lg:pt-3;

      p {
        @apply inline;
      }

      &:last-of-type {
        @apply pb-0;
      }

      &:first-of-type {
        @apply pt-0;
      }

      & ul,
      & ol {
        @apply my-0 ml-6;

        & li {
          @apply pt-3;

          &:first-of-type {
            @apply pt-3;
          }
        }
      }
    }
  }

  strong {
    @apply font-semibold;
  }

  img {
    @apply my-8;
  }

  svg {
    @apply max-w-full h-auto;
  }

  pre {
    @apply bg-gray-97 border border-solid border-gray-85;
  }

  a {
    @apply break-words;
  }

  table {
    @apply whitespace-nowrap overflow-x-auto lg:whitespace-normal lg:overflow-x-auto block border-collapse;
  }

  th,
  td {
    @apply text-12 md:text-16 p-1 md:p-2 border border-solid border-gray-0 text-start;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }
}

.richtext--bold-primary {
  strong {
    @apply text-blue-50;
  }
}


.richtext--img-pop .block-img {
  @apply relative;
}

.richtext--img-pop .block-img::before {
  @apply content-pan-zoom w-7 h-7 absolute right-5 top-5 z-[1] pointer-events-none;
}

.richtext--img-pop .block-img img {
  @apply rounded-2xl relative z-0 cursor-zoom-in;
}




.richtext--img-right {
  img {
    @apply float-right ml-2 mb-2 mt-0 md:ml-5 lg:ml-8 w-full md:w-100 xl:w-137.5;
  }
}
