// Reset & common styles
@import '~ui/styles/include-media.scss';

html {
  //fix iOS bugs reset paddings
  padding: constant(safe-area-inset); //iOS 11
  padding: env(safe-area-inset); //iOS 11.2+
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  // font: var(--fz-paragraph) / var(--lh-paragraph) var(--primary-font);
  color: var(--color-text, #000);
  background-color: #fff;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}

.layout {
  font: var(--fz-paragraph) / var(--lh-paragraph) var(--font-poppins);
  background-color: var(--bg-section);
}

.App {
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

input,
button,
textarea {
  font-family: inherit;
  transition: all 0.2s ease;
}

button {
  cursor: pointer;
  transition: all 0.2s ease;
}

input {
  color: inherit;
  line-height: normal;

  @include placeholder {
    color: inherit;
    opacity: 1;
  }
}

strong {
  font-weight: 500;
}

// .container {
//   width: 100%;
//   max-width: calc(var(--container-width) + (var(--gutter) * 2));
//   padding: 0 var(--gutter);
//   margin: 0 auto;
// }

// .wide-container {
//   max-width: calc(var(--container-wide-width) + (var(--gutter) * 15));
//   padding: 0 calc(var(--gutter) * 7.5);
//   margin: 0 auto;

//   @media (max-width: 1920px) {
//     --max-container-wide-width: calc(var(--container-wide-width) + (var(--gutter) * 15));
//     --adjust-padding: calc(var(--gutter) * 7.5 - (var(--max-container-wide-width) - 100vw) * 0.5);
//     padding: 0 var(--adjust-padding);
//   }
// }

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 0;

  &._tilt-top-right {
    object-position: 80% top;
  }

  &._tilt-top-left {
    object-position: -20% top;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--color-heading, #000);
  font-weight: 500;
  line-height: inherit;
  margin: 0 0 0.5em;
}

h1,
.h1 {
  font-size: var(--fz-h1);
  line-height: 1.32;
}

h2,
.h2 {
  font-size: var(--fz-h2);
}

h3,
.h3 {
  font-size: var(--fz-h3);
}

h4,
.h4 {
  font-size: var(--fz-h4);
}

h5,
.h5 {
  font-size: var(--fz-h5);
}

h6,
.h6 {
  font-size: var(--fz-h6);
}

p {
  margin: 0 0 2em;
}

a {
  position: relative;
  display: inline-block;
  color: var(--color-link);
  transition: all 0.2s ease;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    &:after {
      width: 100%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    transition: inherit;
  }

  &:hover {
    text-decoration: none;
  }
}

// Utils

.mobile-hidden {
  @include media('<tablet') {
    display: none !important;
  }
}

.tablet-hidden {
  @include media('>=tablet', '<desktop') {
    display: none !important;
  }
}

.desktop-hidden {
  @include media('>=desktop') {
    display: none !important;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.25s ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 2000;

  .ModalVideoWrap {
    position: relative;
    width: 100%;
    // height: 100%;
    padding-top: 56.25%;
    div {
      width: 100%!important;
      height: 100%!important;
    }
  }

  .ReactModal__Content {
    width: calc(100% - 60px);
    max-width: 1200px;

    @media only screen and (max-width: 667px) {
      width: calc(100% - 20px);
    }
  }

  iframe {
    // max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.button-mobile-apply {
  font-size: 1em !important;
  display: none !important;
  padding: 1em 1.5em !important;

  @include media('<tablet') {
    display: inline-block !important;
    position: fixed !important;
    bottom: 40px;
    right: var(--gutter);
    z-index: 201;
  }
}

input[type='text']:where(:not([class*="bg-"])),
input[type='tel']:where(:not([class*="bg-"])),
input[type='email']:where(:not([class*="bg-"])),
input[type='search']:where(:not([class*="bg-"])),
input[type='password']:where(:not([class*="bg-"])),
input[type='url']:where(:not([class*="bg-"])),
input[type='number']:where(:not([class*="bg-"])),
input[type='date']:where(:not([class*="bg-"])),
textarea:where(:not([class*="bg-"])){
  -webkit-appearance: none;
  box-sizing: border-box;
  outline-color: transparent;
  outline: none;
  border-style: solid;
  max-width: 100%;
  width: 100%;
  background: transparent;
  padding: 13px 12px;
  font-size: 16px;
  line-height: 1.3;
  border: 1px solid #cccccc;
  border-radius: 4px;

  &:focus {}
}

select {
  box-sizing: border-box;
  outline-color: transparent;
  outline: none;
  border-style: solid;
  max-width: 100%;
  width: 100%;
  background: transparent;
  padding: 10px 12px;
  padding-right: 24px;
  font-size: 16px;
  line-height: 1.3;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

textarea,
input {
  @include placeholder {
    color: var(--alt-text-color);
    opacity: 0.9;
    transition: opacity 0.3s ease;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
    }
  }
}

/* button, */
input[type='button']:not([class*='bg-']),
input[type='reset']:not([class*='bg-']),
// input[type='file'],
input[type='submit']:not([class*='bg-']),
button[type='submit']:not([class*='bg-']) {
  white-space: nowrap;
  line-height: 1;
  display: inline-block;
  vertical-align: top;
  position: relative;
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--btn-bg-color-primary);
  padding: var(--btn-x) var(--btn-y);
  letter-spacing: var(--btn-ls);
  border: 1px solid var(--btn-color);
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
  font-size: var(--btn-fz);
  line-height: 1.2;
  text-align: center;

  &:hover {
    text-decoration: none;
    color: var(--color-white);
    background-color: var(--btn-bg-color-primary-hover);
  }

  &.btn {
    border-radius: 6px;
  }
}

.rounded {
  border-radius: 8px;
}

.btn-show-relative {
  position: relative;
  top: 0.8rem;
  width: 100%;

  @media only screen and (min-width: 993px) {
    width: fit-content;
    top: 4rem;
  }
}

.blue-gradient {
  background: var(--gradient-purple-to-blue);
  margin: 0 !important;
}

.reviews-footer-link {
  position: relative;
  text-align: center;
  width: 100%;

  @include media('>tablet') {
    top: 0.8rem;
    text-align: left;
    width: auto;
  }
}