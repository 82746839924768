.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16vh auto;
  width: 10rem;
  height: 10rem;
  border: 5px solid var(--color-blue);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
