$CDN_URL: 'https://d2mk45aasx86xg.cloudfront.net';

// @font-face {
//   font-family: 'Gordita';
//   src: url('/fonts/GorditaRegular.woff2') format('woff2'),
//     url('/fonts/GorditaRegular.woff') format('woff'),
//     url('/fonts/GorditaRegular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Gordita';
//   src: url('/fonts/GorditaMedium.woff2') format('woff2'),
//     url('/fonts/GorditaMedium.woff') format('woff'),
//     url('/fonts/GorditaMedium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Gordita';
//   src: url('/fonts/GorditaRegular-Italic.woff2') format('woff2'),
//     url('/fonts/GorditaRegular-Italic.woff') format('woff'),
//     url('/fonts/GorditaRegular-Italic.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon/icomoon.woff?ek8ii5') format('woff'),
    url('/fonts/icomoon/icomoon.ttf?ek8ii5') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins-v20-latin-500.woff2') format('woff2'),
    url('/fonts/poppins-v20-latin-500.woff') format('woff'),
    url('/fonts/poppins-v20-latin-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins-v20-latin-regular.woff2') format('woff2'),
    url('/fonts/poppins-v20-latin-regular.woff') format('woff'),
    url('/fonts/poppins-v20-latin-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: '\e910';
  color: #666;
}
.icon-group:before {
  content: '\e90d';
  color: #666;
}
.icon-place:before {
  content: '\e90e';
  color: #666;
}
.icon-business:before {
  content: '\e90f';
  color: #666;
}
.icon-clock:before {
  content: '\e94e';
}
.icon-heart:before {
  content: '\e90b';
  color: #f86567;
}
.icon-whatsup:before {
  content: '\e906';
  color: #25d366;
}
.icon-linkedin:before {
  content: '\e907';
  color: #007ab9;
}
.icon-twitter:before {
  content: '\e908';
  color: #55acee;
}
.icon-facebook:before {
  content: '\e909';
  color: #45619d;
}
.icon-play:before {
  content: '\e903';
}
.icon-chevron-down:before {
  content: '\e904';
}
.icon-brief:before {
  content: '\e905';
}
.icon-right-arrow:before {
  content: '\e900';
}
.icon-chevron-right:before {
  content: '\e901';
}
.icon-chevron-left:before {
  content: '\e902';
}
.icon-star-full:before {
  content: '\e90a';
}
.icon-close:before {
  content: '\e90c';
}
