@import './fonts.scss';
@import '~ui/styles/include-media.scss';
@import './variables.scss';
@import './helpers.scss';

@import './common.scss';
@import './critical.scss';

:root {
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }
  
  @each $name, $color in $bg {
    --bg-#{$name}: #{$color};
  }

  @each $name, $color in $textColor {
    --text-#{$name}: #{$color};
  }

  @each $name, $color in $buttonBg {
    --btn-bg-#{$name}: #{$color};
  }

  @each $name, $color in $cardBg {
    --card-bg-#{$name}: #{$color};
  }

  @each $name, $value in $shadows {
    --shadow-#{$name}: #{$value};
  }

  @each $name, $value in $gradients {
    --gradient-#{$name}: #{$value};
  }

  @each $media, $obj in $variables {
    @if ($media == 'default') {
      @each $name, $value in $obj {
        --#{$name}: #{$value};
      }
    } @else {
      @include media('<=#{$media}') {
        @each $name, $value in $obj {
          --#{$name}: #{$value};
        }
      }
    }
  }

  // --gutter: #{$gutter};

  // @media only screen and (max-width: 767px) {
  //   --gutter: 16px;
  // }

  @media (max-height: 424px) and (min-width: 812px) and (orientation: landscape) {
    //increase paddings that content didn't disturb notch for landscape orientation https://twitter.com/i/status/907911237983449088
    --gutter: 50px;
  }
  --bg-effect-transition-delay: 1.5s;
  --bg-effect-transition-timing-function: cubic-bezier(.4,0,.2,1); //cubic-bezier(.4,0,.2,1)
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #aaa;
}

.bp-widget-side {
  z-index: 9999 !important;
}

.hidden-scroll:not(:hover)::-webkit-scrollbar-thumb,
.hidden-scroll:not(:hover)::-webkit-scrollbar-track {
  background-color: transparent;
}
