.btn {
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: var(--btn-color);
  background-color: var(--btn-bg-color);
  padding: var(--btn-x) var(--btn-y);
  border: 1px solid var(--btn-bg-color);
  letter-spacing: var(--btn-ls);
  font-weight: 500;
  transition: all 0.2s ease-in-out;

  &::first-line {
    line-height: 1;
  }

  &::after {
    display: none;
  }

  &:hover {
    background-color: var(--btn-bg-color-hover);
    border-color: var(--btn-bg-color-hover);
  }

  &:disabled {
    background-color: var(--color-gray);
    pointer-events: none;
  }

  &-outline {
    color: var(--btn-bg-color);
    background-color: transparent;
    border: 1px solid var(--btn-bg-color);

    &:hover {
      color: var(--btn-bg-color);
      background-color: lighten(#2e6edf, 44%);
    }
  }

  &-invisible-link {
    position: relative;
    text-decoration: none;
    outline: none;
    background: none;
    border: none;
  }

  &-link {
    position: relative;
    font-weight: 500;
    color: var(--color-blue);
    letter-spacing: 0.04em;
    text-decoration: none;
    outline: none;
    background: none;
    border: none;

    &:hover {
      &::after {
        @media only screen and (min-width: 993px) {
          width: 100%;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 0;
      background-color: var(--btn-bg-color);
      transition: width 0.2s ease;
    }
  }

  &-link-remove-animation {
    &::after {
      height: 0 !important;
    }
  }

  &-link-gray {
    position: relative;
    color: var(--color-gray);
    text-decoration: none;

    background: none;
    outline: none;
    border: none;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 0;
      background-color: var(--color-gray);
      transition: width 0.2s ease;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  &-link-white {
    position: relative;
    color: var(--color-white);
    text-decoration: none;

    background: none;
    outline: none;
    border: none;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 0;
      background-color: var(--color-white);
      transition: width 0.2s ease;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  &-link-white-v2 {
    position: relative;
    color: var(--color-white);
    text-decoration: none;

    background: none;
    outline: none;
    border: none;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   height: 1px;
    //   width: 0;
    //   background-color: var(--color-white);
    //   transition: width 0.2s ease;
    // }

    // &:hover {
    //   &::after {
    //     width: 100%;
    //   }
    // }
  }

  &-rounded {
    border-radius: 28px;
  }

  &-rounded-sm {
    border-radius: var(--btn-radius);
  }

  &-fluid {
    display: block;
    text-align: center;
    font-weight: 500;

    color: var(--color-white);
    background-color: var(--btn-bg-color);
    padding: 1.2rem;
    border: 1px solid var(--btn-bg-color);
    width: 100%;

    &.btn-arrow {
      &:hover {
        padding-right: 0;
        padding-left: 0;

        &::before {
          display: none;
        }

        &::after {
          opacity: 1;
          transition: all 0.2s linear 0.1s !important;
          margin-left: 0;
        }
      }

      &::before {
        display: none;
      }

      &::after {
        opacity: 0;
        position: relative;
        top: 52%;
        left: 1em;
        content: '\e900';
        font-family: 'icomoon' !important;
        font-size: 0.8em;
        line-height: 1;
        color: inherit;
        margin-top: -0.35em;
        margin-left: -12.8px;
      }
    }
  }

  &-icon {
    margin-right: 10px;
    vertical-align: middle;
  }

  &.primary {
    background: var(--bg-dark);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--bg-dark);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1.5s;
    transition-property: background-color, background, color, border;

    &:hover {
      background: var(--bg-secondary-dark);
    }
  }

  &.secondary {
    background: transparent;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--bg-dark);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    &:hover {
      background: var(--color-light-gray);
    }
  }
}


.stretch {
  width: 100%;
}

.size-md {
  font-size: var(--btn-fz);
}

.size-sm {
  font-size: var(--fz-paragraph-sm);
}

.size-xs {
  font-size: var(--fz-paragraph-xs);
}

.btn-arrow {
  &:hover {
    padding-right: calc(var(--btn-y) * 1.5);

    &::before {
      opacity: 1;
      transition: opacity 0.2s linear 0.1s !important;
    }
  }

  &::before {
    opacity: 0;
    position: absolute;
    top: 52%;
    right: 1em;
    content: '\e900';
    font-family: 'icomoon' !important;
    font-size: 0.8em;
    line-height: 1;
    margin-top: -0.5em;
  }
}

.btn-video {
  padding-left: calc(var(--btn-y) * 2.2);

  &::before {
    position: absolute;
    top: 50%;
    left: 1em;
    content: '\e903';
    font-family: 'icomoon' !important;
    font-size: 20px;
    line-height: 1;
    color: inherit;
    margin-top: -0.5em;
  }
}