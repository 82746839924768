@import '~ui/styles/include-media.scss';

.frame {
  position: relative;
  &OverflowHidden {
    overflow: hidden;
  }
  .noContainerPadding {
    @include media('>widescreen-large') {
      padding: 0px;
    }
    
  }

  @include media('>tablet') {
    padding: 4rem 0;
  }

  &-none {
    padding: 0;
  }

  &-xs {
    padding: 2.8rem 0;

    @include media('>tablet') {
      padding: 3.2rem 0;
    }
  }

  &-sm {
    padding: 3.6rem 0;

    @include media('>tablet') {
      padding: 4rem 0;
    }

    @include media('>desktop') {
      padding: 4.8rem 0;
    }
  }

  &-md {
    padding: 4rem 0;

    @include media('>tablet') {
      padding: 5.6rem 0;
    }

    @include media('>desktop') {
      padding: 8rem 0;
    }
  }

  &-lg {
    padding: 8rem 0;

    @include media('>tablet') {
      padding: 10rem 0;
    }

    @include media('>desktop') {
      padding: 12rem 0;
    }
  }

  &-xl {
    padding: 10rem 0;

    @include media('>tablet') {
      padding: 12rem 0;
    }

    @include media('>desktop') {
      padding: 16rem 0;
    }
  }

  &-top-none {
    padding-top: 0;
  }

  &-top-xs {
    padding-top: 2.8rem;

    @include media('>tablet') {
      padding-top: 3.2rem;
    }
  }

  &-top-sm {
    padding-top: 3.6rem;

    @include media('>tablet') {
      padding-top: 4rem;
    }

    @include media('>desktop') {
      padding-top: 4.8rem;
    }
  }

  &-top-ms {
    padding-top: 4.8rem;

    @include media('>tablet') {
      padding-top: 6.4rem;
    }
  }

  &-top-md {
    padding-top: 4rem;

    @include media('>tablet') {
      padding-top: 5.6rem;
    }

    @include media('>desktop') {
      padding-top: 8rem;
    }
  }

  &-top-lg {
    padding-top: 8rem;

    @include media('>tablet') {
      padding-top: 10rem;
    }

    @include media('>desktop') {
      padding-top: 12rem;
    }
  }

  &-top-xl {
    padding-top: 10rem;

    @include media('>tablet') {
      padding-top: 12rem;
    }

    @include media('>desktop') {
      padding-top: 16rem;
    }
  }

  &-bot-none {
    padding-bottom: 0;
  }

  &-bot-xs {
    padding-bottom: 2.8rem;

    @include media('>tablet') {
      padding-bottom: 3.2rem;
    }
  }

  &-bot-sm {
    padding-bottom: 3.6rem;

    @include media('>tablet') {
      padding-bottom: 4rem;
    }

    @include media('>desktop') {
      padding-bottom: 4.8rem;
    }
  }

  &-bot-ms {
    padding-bottom: 4.8rem;

    @include media('>tablet') {
      padding-bottom: 6.4rem;
    }
  }

  &-bot-md {
    padding-bottom: 4rem;

    @include media('>tablet') {
      padding-bottom: 5.6rem;
    }

    @include media('>desktop') {
      padding-bottom: 8rem;
    }
  }

  &-bot-lg {
    padding-bottom: 8rem;

    @include media('>tablet') {
      padding-bottom: 10rem;
    }

    @include media('>desktop') {
      padding-bottom: 12rem;
    }
  }

  &-bot-xl {
    padding-bottom: 10rem;

    @include media('>tablet') {
      padding-bottom: 12rem;
    }

    @include media('>desktop') {
      padding-bottom: 16rem;
    }
  }

  &-t-align-center {
    @include media('>=desktop') {
      .headlineCol {
        text-align: center;
        width: 80%;

        .title {
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
}

.headline {
  display: block;

  @include media('>=desktop') {
    display: flex;
    justify-content: space-between;
  }

  &:empty {
    margin-bottom: 0 !important;
  }

  &-margin-none {
    margin-bottom: 0 !important;
  }

  &-margin-sm {
    margin-bottom: 24px;

    @include media('>tablet') {
      margin-bottom: 32px;
    }
  }

  &-margin-md {
    margin-bottom: 32px;

    @include media('>tablet') {
      margin-bottom: 48px;
    }
  }

  &-margin-lg {
    margin-bottom: 40px;

    @include media('>tablet') {
      margin-bottom: 64px;
    }
  }

  &-margin-press {
    margin-bottom: 56px;

    @include media('>tablet') {
      margin-bottom: 64px;
    }
  }

  &-margin-xl {
    margin-bottom: 40px;

    @include media('>tablet') {
      margin-bottom: 80px;
    }
  }
}

.bg {
  &-white {
    background-color: var(--color-white);
  }

  &-gray-light {
    background-color: var(--color-gray-extra-light);
  }

  &-light-gray {
    background-color: var(--color-light-gray);
  }

  &-gray {
    background-color: #f8f8f8;
  }

  &-blue-gradient {
    background: linear-gradient(90deg, #6c7bd4 0%, #56a8f7 100%);
    color: var(--color-white);
  }
  &-purple-gradient {
    background: linear-gradient(90deg, #1c48bf 0%, #b93dfd 100%);
    color: var(--color-white);
  }
}

.color {
  &-white {
    color: var(--color-white);
  }
}

.headline-align-center {
  justify-content: center;
  width: 100%;
}
