@use 'sass:map';
@import './styles/scss/variables.scss';
@import '~ui/styles/include-media.scss';

.root {
  display: flex;
  align-items: center;
  padding: 28px 0;

  &-dark {
    &>* {
      color: var(--text-primary-black)
    }

    & .content {
      color: var(--text-secondary-black)
    }

    & .chip {
      background-color: var(--bg-section);
    }
  }

  &-light {
    &>* {
      color: var(--text-primary-white)
    }
  }

  &:hover .title {
    color: var(--btn-bg-hero);
  }

  @include media('<tablet') {
    padding: 24px 0;
  }
}

.body {
  flex: 1;
  margin-right: 24px;
}

.chip {
  font-size: var(--fz-paragraph-xs);
  line-height: 18px;
  vertical-align: middle;
  border-radius: 9px;
  background-color: var(--color-gray);
  display: inline-block;
  padding: 0 8px;
  margin-bottom: 8px;
}

.title {
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: 500;

  &>em {
    background-color: var(--btn-bg-hero);
    color: var(--text-primary-white);
    border-radius: var(--border-radius-sm);
    padding: 0 4px;
  }
}

.content {
  &>em {
    background-color: var(--btn-bg-hero);
    color: var(--text-primary-white);
    border-radius: var(--border-radius-sm);
    padding: 0 4px;
  }
}

.date {
  margin-top: 8px;
  color: var(--color-gray);
  font-size: var(--fz-paragraph-xs);
}

.image {
  width: 192px;
  height: 108px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  img {
    object-fit: cover;
  }

  @include media('<phone') {
    display: none;
  }
}