@import '~ui/styles/include-media.scss';

.btnVideo {
  display: inline-flex;
  align-items: center;
  font-size: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  background-color: transparent;
  color: var(--color-white);
  border: none;
  outline: none;
  border: 1px solid var(--color-blue);
  background-color: var(--color-blue);
  border-radius: 25px;
  padding: 8px 8px 8px 10px !important;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    color: var(--color-blue);
    background-color: var(--color-white);
  }

  &-icon {
    font-weight: normal;
    font-size: 2.5em;
    line-height: 1;
    margin-right: 16px;

    @media only screen and (max-width: 767px) {
      font-size: 2.08em;
      margin-right: 10px;
    }
  }

  @include media('>tablet') {
    padding: 8px 16px 8px 16px !important;
  }
}

.modalClose {
  position: absolute;
  top: -28px;
  right: -28px;
  font-size: 0;
  line-height: 0;
  padding: 0;
  z-index: 10;
  color: var(--color-white);
  cursor: pointer;
  transition: opacity 0.2s linear;
  width: 24px;
  height: 24px;

  @media (max-width: 665px) {
    right: 0;
    top: -32px;
  }

  &:hover {
    opacity: 0.5;
  }

  &:before {
    display: inline-block;
    vertical-align: top;
    content: '\00D7';
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }
}
