// Any above the fold CSS should be existing here and be used instead of component-level CSS

.breadcrumb-list {
  display: flex;
}

.breadcrumb-list-item {
  padding-right: 1rem;

  & a {
    font-weight: 500;
  }

  &::after {
    position: relative;
    content: '\e901';
    font-family: 'icomoon' !important;
    font-size: 1rem;
    color: var(--color-gray);
    margin-left: 1rem;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}
